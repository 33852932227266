
 // Global Colors
$blue: #008af4;
$blueDark: #3b5998;
$green: #34BB9F;
$greenDark: #16AC8D;
$keppel: #2F5862;
$darkCyan: #284E57;
$darkTeal: #23454D;
$red: #DE6A67;
$ghost: #FBFBFB;
$white: #fff;
$windowTint: #DCDCDC;
$grey: #BEBEBE;
$smokeGrey: #7E7E7E;
$lightBlue: #E4F3FA;
$black: #000000;


/** New Style Vars **/
// Main
$primary: #60B8A0;
$primaryLighten: rgba(96, 184, 160, 0.4);
$secondary: #284E57;
$unavailable: #FFAD2B;
// Memberships
$platinum: #C1B3CF;
$gold: #FFAD2B;
// Greys
$lightGrey: #BFBFBF;
$midGrey: #828282;
$neutral: #E7E7E7;
$neutralText: #9D9D9D;
// Black / Off-Black
$offblack: #1C1C1C;

// Gradients

$platinumGradient: linear-gradient(71.53deg, #545a6e 0.15%, #c0baca 57.72%);
$goldGradient: linear-gradient(71.53deg, #FF6201 0.15%, #FFC258 57.72%);
$freeGradient: linear-gradient(71.53deg, #589786 0.15%, #60B8A0 57.72%);

// Textures
$largeTexture: url(./assets/img/texture-large.png);
$smallTexture: url(./assets/img/texture-small.png);

// Font
$merriweather: 'Merriweather', serif;
$cerebriSans: 'Cerebri Sans', sans-serif;