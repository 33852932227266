@import 'fonts';
@import "variables";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import '~js-datepicker/dist/datepicker.min.css';

html.slideout-open, html.slideout-open body {
  overflow: hidden;
}

html app-profile-options {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
  font-family: "CerebriSans";
  font-size: 1rem;
}

body {
  background: #f5f5f5;
}
body {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

body { 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

html, body, p {
	font-weight: 300;
}

p, div, span {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 20px;
  font-weight: 300;
}

p {
  margin-bottom: .3em;
}

h2, h3, h4 {
  color: #1c1c1c;
  font-weight: 400;
}

h1 {
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: $darkCyan;
  font-weight: 400;
}
h2{
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
  margin: 1em 0 .3em;
}

html {
	height: 100%;
}

a {
  color: #25bfa1;
  text-decoration: none;
  &:hover {
    color: mix(black, #25bfa1, 10%);
  }
}

b{
  font-weight: 600;
}
strong {
  font-weight: 400;
}


/*
 * Global Spinner
 */
 @keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.large-container {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.25);
  margin-top: 39px;
}

.spinner {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $green;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $green transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.ng-select.link-dropdown .ng-select-container {
  -webkit-appearance:none;
  outline: none;
  border: none;
  background: none;
  color: $darkCyan;
  text-decoration: underline;
  width: 200px;
  .ng-value-container {
    .ng-placeholder {
      color: $darkCyan;
    }
    .ng-template {
      div {
        font-size: 14px;
      }
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.strong2 {
  font-weight: 600;
}
.strong3 {
  font-weight: 800;
}

.button {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
}

.button.regular {
  border-radius: 25px;
  padding: 8px 15px;
  transition: all .25s ease-in-out;
  font-weight: 700;
  font-size: 0.9rem;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  height: 40px;
  margin: 0 4px;
  &:hover {
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
  }
  &:focus {
    outline: none;
  }
}

%small-button {
  font-size: 0.87rem;
  border-radius: 25px;
  height: auto;
  padding: 8px 12px;
  margin: 8px 8px;
}

%solid {
  border: none;
  color: $white;
}

%outline {
  border-width: 2px;
  border-style: solid;
  background-color: transparent;
}

.solid-green {
  @extend %solid;
  background-color: $green;
  &:hover {
    background-color: mix(black, $green, 10%);
  }
}

.justify-content-end {
  justify-content: end;
}

.flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.italic {
  font-style: italic;
}

.flex-direction-column, .flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.outline-green {
  @extend %outline;
  border-color: $green;
  color: $green;
  &:hover {
    text-decoration: none;
    box-shadow: none;
    background-color: $green;
    color: $white;
  }
}

.outline-grey {
  @extend %outline;
  border-color: $grey;
  color: $smokeGrey;
}

.solid-grey {
  color: $grey;
}

.outline-red {
  @extend %outline;
  border-color: $red;
  color: $red;
}

.outline-blue {
  @extend %outline;
  border-color: $blue;
  color: $blue;
}

.solid-blue {
  @extend %solid;
  background-color: $blue;
  &:hover {
    background-color: mix(black, $blue, 10%);
  }
}

.solid-red {
  @extend %solid;
  background-color: $red;
  &:hover {
    background-color: mix(black, $red, 10%);
  }

}

.icon {
  font-family: "IonIcons", sans-serif;
  font-size: 16px;
  color: #fff;
}

.blockClick{
  pointer-events: none;
}


/*
 * Modal Styles
 */
app-modal {
  display: none;
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-body {
      border-radius: 25px;
      background: #fff;
      overflow: auto;
      min-width: 320px;
      max-width: 80%;
      max-height: 80%;
      -ms-overflow-style: none;

    }
  }
  .modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .65);
    z-index: 900;
  }
  .confirm-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .65);
    z-index: 1000;
  }
}
body.modal-open {
  overflow: hidden;
}

/*
 * Promo Wizard Styles
 */

.search input#book {
   background-image: url(./assets/img/search.png);
   background-repeat: no-repeat;
   height: 40px;
   width: 100%;
   border: 1px solid $windowTint;
   box-sizing: border-box;
   box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
   text-indent: 10px;
   background-size: 20px;
   background-position-x: 10px;
   padding-left: 35px;
   background-position-y: 8px;
   font-size: 14px;
   font-style: italic;
   color: $grey;
   border-radius: 25px;

   &::placeholder {
     color: $grey;
     font-size: 14px;
     font-style: italic;
   }

   &:focus {
     background-image: none;
     padding-left: 10px;
     text-indent: 0px;
   }
 }

.promo-wizard-input input {
  padding-left: 50px !important;
}

.promo-wizard-input input,
.genres-selection,
.selected-book {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  height: 46px !important;
  background-color: #fff !important;
}

.promo-wizard-input.price {
  top: -43px;
  position: inherit;
}

.promo-wizard-input.price::before, #promo_price::before {
  content: "$";
  position: relative;
  top: 66px;
  left: 5px;
  width: 14px;
  font-size: 21px;
  border-right: 1px solid #DCDCDC;
  padding: 12px;
  color: rgb(11 11 11 / 40%);
  font-weight: 900;
  display: block;
  z-index: 1;
}

.promo-wizard-input:focus {
  border: none !important;
  outline: none;
}



/*
 * DatePicker Styles
 */
my-date-picker {
  .mydp {
    border-radius: 0 !important;
    border: none;
    .selectiongroup {
      border-radius: 0 !important;
      input {
        padding: 0 8px;
        font-size: 16px;
        line-height: 40px;
        background-color: #fafafa;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 300 !important;
        border: 1px solid rgba(0, 0, 0, .15) !important;
        border-radius: 0 !important;
        &:focus {
          background-color: #f5f5f5 !important;
          border-color: $blue !important;
          outline: none !important;
        }
      }
      .selbtngroup {
        button {
          background-color: $blue;
          transition: all .25s ease-in-out;
          border-radius: 0;
          span {
            color: #fff !important;
          }
          &:hover {
            background-color: #0f97ff;
            text-decoration: none;
            box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
          }
        }
      }
    }
    .selector {
      overflow: hidden;
    }
  }
}

.promo-wizard-input.calendar {
  my-date-picker {
    .mydp {
      border-radius: 25px !important;

      .selectiongroup {
        height: 46px !important;
        border-radius: 25px !important;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
        width: 100%;

        &::before {
          background: url(./assets/img/promo-wizard/promo-wizard-icon-calendar.png);
          content: "";
          position: absolute;
          background-repeat: no-repeat;
          top: 0;
          left: 5px;
          border-right: 1px dashed #999999;
          padding: 12px;
          color: rgba(11, 11, 11, 0.4);
          background-size: 25px;
          margin-left: 0px;
          height: 21px;
          width: 24px;
          padding-right: 2px;
          background-position: center;
          opacity: 40%;
        }

        input {
          padding: 0 8px;
          font-size: 16px;
          background-color: transparent !important;
          color: rgba(0, 0, 0, .6) !important;
          font-weight: 300 !important;
          border: 1px solid rgba(0, 0, 0, .15) !important;
          border-radius: 25px !important;
        }
        
        .selbtngroup {
          button {
            background-color: $blue;
            transition: all .25s ease-in-out;
            border-radius: 0;
            width: 40px;

            span {
              color: #fff !important;
            }

            &:hover {
              background-color: #0f97ff;
              text-decoration: none;
              box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
            }

            &.btnpicker {
              display: none;
            }

            &.btnclear {
              border-radius: 0 25px 25px 0;
            }
          }
        }
      }

      .selector {
        overflow: hidden;
      }
    }
  }
}

.bold-underline {
  color: var(--Dark, #284E57);
  text-align: center;
  font-family: $cerebriSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

// New Date picker

.qs-active {
  background-color: #d6e9f8;
  text-decoration: none;
  color: #026ef8;
}

.qs-controls {
  background-color: white;
  border-bottom: 1px solid lightgrey;
}

.qs-square.Sat {
  border-right: 1px solid lightgrey;
}

.qs-square {
  border-left: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-radius: 0px;
  padding: 19px !important;
}

.qs-day {
  background-color: #d6e9f8;
  border-top: 1px solid lightgrey;
  color: #026ef8;
  font-weight: 100;
}

.qs-day:last-child {
  border-right: 1px solid lightgrey;
}

.qs-disabled {
  opacity: 1;
  background-color: #f3f3f3;
  color: grey;
}

.qs-empty {
  opacity: 1;
}

.qs-datepicker-container {
  width: 298px
}

.qs-square:hover {
  background-color: #026ef8 !important;
  color: white;
}

/*
 * Global App Block (for site adverts and sites html)
 */

.app-html-block {
  div {
    color: rgba(0, 0, 0, .6);
    line-height: 24px;
    min-height: 24px;
  }

  h1, h2, h3, h4 {
    color: #1c1c1c;
  }
  h1 {
    padding: 16px 0 16px;
    line-height: 32px;
  }
  h3 {
    padding: 16px 0 16px;
    line-height: 27px;
  }
  h4 {
    padding-bottom: 8px;
  }
  ol, ul {
    margin-left: 20px;
  }
  .top {
    display: inline-block;
  }
  a {
    color: $blueDark;
  }
  @media(max-width: 767px) {
    h1, h2, h3, h4 {
      padding: 8px;
    }
  }
}

.float-right {
  float: right;
}

/*
* Global for Support Questions/Answers
*/
.support-answer {
  margin-top: 10px;
  p {
    line-height: 20px;
    color: rgba(0, 0, 0, .86) !important;
  }
}

/*
* Global for Stripe Form
*/

.StripeElement {
  background-color: #fafafa;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 75%;
  margin: 0 auto 10px;
  &:focus {
    background-color: #f5f5f5;
    border-color: $blue;
    outline: none;
  }
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*
* Global for Blog Post Tile
*/
.tile.blog {
  .post-title {
    a {
      font-size: 20px;
      color: #000;
      font-weight: 400;
    }
  }
  .post-meta {
    margin: 10px 0;
    font-size: 14px;
    color: #777;
    list-style-type: none;
    li {
      display: inline-block;
    }
  }
  p {
    margin-top: 10px;
  }
}

.sectionContainer{
  padding: 40px;
  gap: 30px 4%;
  position: relative;
  .sectionContainer__header{
    background-color: #fff;
    height: 120px;
    width: 100%;
    border: 1.5px dashed rgba(40, 78, 87, 0.3);
    border-radius: 25px;
    min-height: 200px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 2vW;
    h1 {
      margin-bottom: 8px;
    }
    b{
      font-size: 1.8rem;
      line-height: 4rem;
      color: #284E57;
      font-weight: 500;
    }
    p{
      color: #9c9c9c;
      font-weight: 400;
      font-size: 1.25rem;
      a{
        font-size: 1.25rem;
        text-decoration: underline;
      }
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 200px;
      object-fit: contain;
    }
  }
}

/*
* Global block
*/
.block {
  box-shadow: 0px 0px 3px rgb(0 0 0 / 15%);
  border-radius: 3px;
  background-color: #ffffff;
  padding: 32px;
}

@media(max-width: 767px) {
  h3 {
    font-size: 1.3rem;
    margin: 0;
  }
}


/*
* NEW STYLES 4/13/2023
*/

// Global

.lighten-40 {
  opacity: 40%;
}

.lighten-30 {
  opacity: 30%;
}

.primary {
  background-color: $primary;
}

.primary-lighten {
  background-color: $primaryLighten;
}

.neutral {
  background-color: $neutral;
}

.neutral-text, .primary-text, .secondary-text, .gold-text, .platinum-text {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.neutral-text {
  color: $neutralText;
}

.primary-text {
  color: $primary;
}

.secondary-text {
  color: $secondary;
}

.gold-text {
  color: $gold;
}

.platinum-text {
  color: $platinum;
}

.error-text {
  font-size: 10px;
  color: $red;
}

.underline {
  text-decoration: underline;
}

.gold-background {
  background: $goldGradient;
}

.platinum-background {
  background: $platinumGradient;
}

.gold .wwm-icon {
  background-image: url(/assets/img/gold-icon.svg);
  background-size: cover;
}

.platinum .wwm-icon {
  background-image: url(/assets/img/platinum-icon.svg);
  background-size: cover;
}

.free .wwm-icon {
  background-image: url(/assets/img/free-icon.svg);
  background-size: cover;
  background-color: #34bb9f;
}

.text-align-center {
  text-align: center;
}

.align-content-center {
  align-content: center;
}

.card-badge {
  border-radius: 9px;
  width: 107px;
  height: 80px;
  color: #FFF;
  text-align: center;
  font-family: $merriweather;
  font-size: 17.541px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  .wwm-icon {
    width: 32px;
    height: 32px;
    margin: 13px auto 0 auto;
  }
}

.free .texture {
  opacity: .2;
}

.gold .texture {
  opacity: .3;
  filter: invert(72%) sepia(62%) saturate(3712%) hue-rotate(336deg) brightness(110%) contrast(263%);
}

.platinum .texture {
  opacity: .3;
  filter: invert(93%) sepia(15%) saturate(255%) hue-rotate(212deg) brightness(90%) contrast(91%);
}

.texture {
  background: url(./assets/img/texture-large.png), no-repeat;
  width: 100%;
  height: 100%;
}

// Tables

.row .col {
  float: left;
}

.col-2 {
  width: 16.666666666%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.333333%;
}

.col-5 {
  width: 41.666666666%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.333333333%;
}

.col-8 {
  width: 66.666%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.333333333%;
}

.clear {
  clear: both;
}

// BUTTONS

.btn {
  padding: 13px 21px;
  border-radius: 50px;
  background-color: $primary;
  border: 1px solid $primary;
  color: white;
  font-family: $cerebriSans;
  white-space: nowrap;
}

.btn.huge {
  padding: 13px 48px;
}

.btn:hover {
  cursor: pointer;
}

.btn.sm {
  padding: 8px 32px;
}

.btn.disabled {
  background-color: $primaryLighten;
  border: 1px solid $primaryLighten;
  color: $neutralText;
  cursor: not-allowed;
}

.btn.sm-square {
  padding: 6px 32px;
  border-radius: 10px;
  width: fit-content;
}

.btn.xsm-square {
  padding: 6px 41px;
  border-radius: 8px;
}

.btn.invert {
  background-color: white;
  color: $primary;
}

.btn.light {
  font-family: 'CerebriSans';
  background-color: $primaryLighten;
  color: $secondary;
  border: none;

}

.btn.block {
  width: 100%;
  box-shadow: unset !important;
}

.btn.pad {
  padding: 13px 48px;
}

// Cards
.card {
  border-radius: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.16);
  height: 100%;
}

.flat {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid var(--Light-grey, #BFBFBF);
}

// Badge

.badge {
  border-radius: 4px;
}

.badge.sm {
  padding: 4px 8px;
}

.badge.lg {
  padding: 8px 13px;
}

// Lists

li.pen::marker {
  content: url(./assets/img/pen-bullet.svg);
}

li.eyes::marker {
  content: "👀";
}

li.money::marker {
  content: "💰";
}

li.bell::marker {
  content: "🛎";
}

li.flashlight::marker {
  content: "🔦";
}

li.comment::marker {
  content: "💬";
}

li.smile::marker {
  content: "🤓";
}

li.pen, li.pen div, li.eyes, li.money, li.bell, li.flashlight, li.comment, li.smile {
  padding-left: 11px;
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
}

// Icons

.icon-arrow-down, .icon-arrow-left, .icon-arrow-right, .icon-arrow-up {
  width: 8px;
  height: 15px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background: url(./assets/img/arrow.svg);
}

.icon-arrow-down {
  transform: rotate(-90deg);
  position: relative;
  top: 3px;
  left: 5px;
}

.icon-arrow-right {
  transform: rotate(180deg);
}

.icon-arrow-up {
  transform: rotate(90deg);
}

.promo-stack-form a {
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
}

/* width */
.modal-body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
  margin: 20px 0;
}
 
/* Handle */
.modal-body::-webkit-scrollbar-thumb {
  background: rgb(52, 52, 52); 
  border-radius: 10px;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: #090909; 
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.book-spinner {
  --color: #49767E;
  --duration: 4s;
  width: 32px;
  height: 12px;
  position: relative;
  margin: 32px 0 85px 0;
  animation: rotate-book 4s linear infinite; 
}

.spinner-text {
  font-weight: 400;
}

.inner {
  width: 32px;
  height: 12px;
  position: relative;
  transform-origin: 2px 2px;
  transform: rotateZ(-90deg);
  animation: book var(--duration) ease infinite;
  color: #5DBCA3;
}

.spinner-container .left,
.spinner-container .right {
  width: 60px;
  height: 4px;
  top: 0;
  border-radius: 2px;
  background: var(--color);
  position: absolute;
}

.spinner-container .left:before,
.spinner-container .right:before {
  content: '';
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: #5DBCA3;
  position: absolute;
  top: -10px;
  left: 6px;
}

.spinner-container .left {
  right: 28px;
  transform-origin: 58px 2px;
  transform: rotateZ(90deg);
  animation: left var(--duration) ease infinite;
}

.spinner-container .right {
  left: 28px;
  transform-origin: 2px 2px;
  transform: rotateZ(-90deg);
  animation: right var(--duration) ease infinite;
}

.spinner-container .middle {
  width: 32px;
  height: 12px;
  border: 4px solid var(--color);
  border-top: 0;
  border-radius: 0 0 9px 9px;
  transform: translateY(2px);
}

.spinner-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 50%;
  top: 0;
}

.spinner-container ul li {
  height: 4px;
  border-radius: 2px;
  transform-origin: 100% 2px;
  width: 48px;
  right: 0;
  top: -10px;
  position: absolute;
  background: #5DBCA3;
  transform: rotateZ(0deg) translateX(-18px);
  animation-duration: var(--duration);
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

#loading-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* or any color matching your app's style */
  z-index: 9999;
}

/* Keyframe for rotating the entire book 360 degrees */
@keyframes rotate-book {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Adjusted animations for 8 list items */
@keyframes page-1 {
  4% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  13%, 54% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  63% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-2 {
  8% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  17%, 57% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  67% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-3 {
  12% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  21%, 61% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  71% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-4 {
  16% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  25%, 65% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  75% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-5 {
  20% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  29%, 69% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  79% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-6 {
  24% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  33%, 73% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  83% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-7 {
  28% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  37%, 77% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  87% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-8 {
  32% {
      transform: rotateZ(0deg) translateX(-18px);
  }
  41%, 81% {
      transform: rotateZ(180deg) translateX(-18px);
  }
  91% {
      transform: rotateZ(0deg) translateX(-18px);
  }
}

.spinner-container ul li:nth-child(1) {
  animation-name: page-1;
}
.spinner-container ul li:nth-child(2) {
  animation-name: page-2;
}
.spinner-container ul li:nth-child(3) {
  animation-name: page-3;
}
.spinner-container ul li:nth-child(4) {
  animation-name: page-4;
}
.spinner-container ul li:nth-child(5) {
  animation-name: page-5;
}
.spinner-container ul li:nth-child(6) {
  animation-name: page-6;
}
.spinner-container ul li:nth-child(7) {
  animation-name: page-7;
}
.spinner-container ul li:nth-child(8) {
  animation-name: page-8;
}

@keyframes left {
  4% {
      transform: rotateZ(90deg);
  }
  10%, 40% {
      transform: rotateZ(0deg);
  }
  46%, 54% {
      transform: rotateZ(90deg);
  }
  60%, 90% {
      transform: rotateZ(0deg);
  }
  96% {
      transform: rotateZ(90deg);
  }
}

@keyframes right {
  4% {
      transform: rotateZ(-90deg);
  }
  10%, 40% {
      transform: rotateZ(0deg);
  }
  46%, 54% {
      transform: rotateZ(-90deg);
  }
  60%, 90% {
      transform: rotateZ(0deg);
  }
  96% {
      transform: rotateZ(-90deg);
  }
}

@keyframes book {
  4% {
      transform: rotateZ(-90deg);
  }
  10%, 40% {
      transform: rotateZ(0deg);
      transform-origin: 2px 2px;
  }
  40.01%, 59.99% {
      transform-origin: 30px 2px;
  }
  46%, 54% {
      transform: rotateZ(90deg);
  }
  60%, 90% {
      transform: rotateZ(0deg);
      transform-origin: 2px 2px;
  }
  96% {
      transform: rotateZ(-90deg);
  }
}