@mixin font-face($name, $src, $weight: 300, $style: normal) {
  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    @if ($name == 'CerebriSans') {
      src: url($src + '.otf') format('otf');
      src: url($src + '.ttf') format('truetype');
      //src: url($src + '.ttf') format('truetype');
    } @else if ($name == 'Circular') {
      src: url($src + '.otf') format('otf');
      src: url($src + '.ttf') format('truetype');
      //src: url($src + '.ttf') format('truetype');
    } @else if($name == 'Merriweather') {
      src: url($src + '.ttf') format('truetype');
    } @else {
      src: url($src + '.woff2') format('woff2');
      src: url($src + '.woff') format('woff');
      //src: url($src + '.ttf') format('truetype');
    }
  }
}

@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Thin', 100);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-ThinItalic', 100, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Light', 200);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-LightItalic', 200, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Regular', 300);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Italic', 300, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Medium', 400);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-MediumItalic', 400, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Bold', 500);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-BoldItalic', 500, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Black', 600);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-BlackItalic', 600, italic);

@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-Light', 200);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-LightItalic', 200, italic);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-Regular', 300);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-Italic', 300, italic);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-Medium', 400);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-MediumItalic', 400, italic);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-Bold', 500);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-BoldItalic', 500, italic);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-Black', 600);
@include font-face('Rubik', '/assets/fonts/Rubik/Rubik-BlackItalic', 600, italic);

@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansLight', 200);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansLightItalic', 200, italic);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansRegular', 300);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansItalic', 300, italic);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansMedium', 400);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansMediumItalic', 400, italic);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansBold', 500);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansBoldItalic', 500, italic);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansExtraBold', 700);
@include font-face('CerebriSans', '/assets/fonts/Cerebri-Sans/CerebriSansExtraBoldItalic', 700, italic);

@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-Light', 200);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-LightItalic', 200, italic);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-Regular', 300);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-Italic', 300, italic);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-Bold', 700);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-BoldItalic', 700, italic);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-Black', 900);
@include font-face('Merriweather', '/assets/fonts/Merriweather/Merriweather-BlackItalic', 900, italic);

@include font-face('Circular', '/assets/fonts/Circular/CircularStdMedium', 200);
@include font-face('Circular', '/assets/fonts/Circular/CircularStdBold', 300, bold);

@font-face {
  font-family: 'IonIcons';
  src: url('/assets/fonts/ionicons.ttf') format('truetype');
}
